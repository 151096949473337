import React from "react";
import { FormikProps } from "formik";
import { getIn } from "formik";

interface FormikErrorProps {
	formik: FormikProps<any>;
	field: string;
}

const FormikError: React.FC<FormikErrorProps> = ({ formik, field }) => {
	const error = getIn(formik.errors, field);
	const touched = getIn(formik.touched, field);

	if (!touched || !error) return null;

	return <p className="text-red-500 text-sm mt-1">{error}</p>;
};

export default FormikError;
