import React from "react";
import { FormikProps } from "formik";
import FormikError from "./FormikError";

interface DateInputProps {
	name: string;
	formik: FormikProps<any>;
	label: string;
	min?: string;
	max?: string;
	disabled?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({ name, formik, label, min, max, disabled }) => {
	return (
		<>
			<label className="block">
				{label}
				<span className="text-red-500">*</span>
			</label>
			<input type="date" name={name} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} min={min} max={max} disabled={disabled} className={`w-full px-3 py-2 border rounded ${disabled ? "disabledStyle" : "theme-bg"} input-registered-required`} />
			<FormikError formik={formik} field={name} />
		</>
	);
};

export default DateInput;
