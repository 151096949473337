import dayjs from "dayjs";
import { show } from "../notification/notificationSlice";
import { AppDispatch } from "../../app/store";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const asterisk = <span className="text-red-500">*</span>;

export const formatPhoneNumber = (value: string) => {
	return value
		?.replace(/\D/g, "")
		?.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, (match, p1, p2, p3) => {
			if (p3) return `${p1}-${p2}-${p3}`;
			else if (p2) return `${p1}-${p2}`;
			else if (p1) return `${p1}`;
			return "";
		})
		.slice(0, 12);
};
export const disabledBgColor = "bg-gray-200";
export const isValidPhoneNumber = (value: string) => {
	const formattedValue = formatPhoneNumber(value);
	return /^\d{3}-\d{3}-\d{4}$/.test(formattedValue);
};

export const shortDate = (date: string) => {
	return dayjs(date).utc().format("MM/DD/YYYY");
};
export const dateFormatter = (field: string, data: any) => {
	return dayjs(data).format("MM/DD/YYYY");
};

export const displayToast = (dispatch: AppDispatch, status: "info" | "warn" | "error" | "success" | "delete" | "promote" | "cancel", title: string, message: string) => {
	dispatch(
		show({
			show: true,
			title,
			message,
			status,
			position: "popover",
			autoHide: 5000,
			confirm: false,
			notificationId: null,
		})
	);
};
