import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from "yup";
import FormikError from "./components/FormikError";
import { asterisk, disabledBgColor, displayToast, isValidPhoneNumber } from "./utils";
import { BeatLoader } from "react-spinners";
import { useAppDispatch } from "../../app/hooks";
import PhoneNumberInput from "./PhoneNumberInput";
import { ExclamationCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import { ThemeContext } from "../../components/settings/theme/ThemeContext";
import FormInput from "./components/FormInput";
import DateInput from "./components/DateInput";
import CobuyerForm from "./components/CobuyerForm";
import { BuyerAgreementRequest } from "../../adapters/buyerRegistrationApi";
import { createPrimaryBuyer, updateBuyer } from "../../adapters";
import { useSelector } from "react-redux";
import { userId } from "../user/selectors";
import { Buyer } from "./BuyerRegistration";
interface RegisterBuyerFormProps {
	buyer?: Buyer;
	searchPhoneNumber?: string;
	setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
	refreshTable: () => void;
	agreementTypeOptions: any;
}

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.trim()
		.required("Name is required")
		.test("is-not-empty", "Name cannot be empty", (value) => value?.trim() !== ""),
	phoneNumber: Yup.string()
		.required("Primary Phone Number is required")
		.matches(/^\d{3}-\d{3}-\d{4}$/, "Phone number is not valid"),
	emailAddress: Yup.string().email("Invalid email address").required("Email is required"),
	writtenAgreementExecuted: Yup.boolean().oneOf([true], "Verification is required"),
	startDate: Yup.date().required("Start Date is required"),
	endDate: Yup.date()
		.required("End Date is required")
		.test("is-after-start-date", "End Date must be after Start Date", function (value) {
			const { startDate } = this.parent;
			return !startDate || !value || new Date(value) > new Date(startDate);
		}),
	agreementType: Yup.string().required("Agreement Type is required"),
	cobuyers: Yup.array().of(
		Yup.object().shape({
			name: Yup.string()
				.trim()
				.required("Co-buyer Name is required")
				.test("is-not-empty", "Co-buyer Name cannot be empty", (value) => value?.trim() !== ""),
			phoneNumber: Yup.string()
				.required("Co-buyer Phone Number is required")
				.matches(/^\d{3}-\d{3}-\d{4}$/, "Phone number is not valid"),
			emailAddress: Yup.string().email("Invalid email address").required("Co-buyer Email is required"),
			otherAgentsExclusive: Yup.number().nullable(),
			otherAgentsNonExclusive: Yup.number().nullable(),
		})
	),
});

const RegisterBuyerForm: React.FC<RegisterBuyerFormProps> = ({ buyer, searchPhoneNumber, setShowSidePanel, refreshTable, agreementTypeOptions }) => {
	const addButtonRef = useRef<HTMLButtonElement>(null);
	const [isSaving, setIsSaving] = useState(false);
	const { theme } = useContext(ThemeContext);
	let memberId = useSelector(userId);

	const formik = useFormik({
		initialValues: {
			name: buyer?.name || "",
			phoneNumber: buyer?.phoneNumber || searchPhoneNumber || "",
			emailAddress: buyer?.emailAddress || "",
			writtenAgreementExecuted: buyer?.writtenAgreementExecuted || false,
			startDate: buyer?.startDate ? new Date(buyer.startDate).toISOString().split("T")[0] : "",
			endDate: buyer?.endDate ? new Date(buyer.endDate).toISOString().split("T")[0] : "",
			agreementType: buyer?.agreementType || "",
			cobuyers: buyer?.coBuyers || [],
			agreementId: buyer?.agreementId || "",
			status: buyer?.status || "",
		},
		validationSchema,
		onSubmit: (values) => {
			setIsSaving(true);
			console.log("Values, ", values);
			console.log("Save Values1", values);
			const apiCall = buyer ? updateBuyer : createPrimaryBuyer;
			apiCall({ ...values } as any, memberId)
				.then((response) => {
					setIsSaving(false);
					refreshTable();
					displayToast(dispatch, "success", "Buyer Registration", "Buyer Registration has been saved successfully");
					setShowSidePanel(false);
				})
				.catch((error) => {
					setIsSaving(false);
					displayToast(dispatch, "error", "Buyer Registration", "An error has occured.");
				});
		},
	});

	const dispatch = useAppDispatch();
	const disabledStyle = !!buyer && theme === "dark" ? "bg-gray-800" : !!buyer && theme === "contrast" ? "contrast-background" : disabledBgColor;

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit} className={`${isSaving ? "pointer-events-none" : ""}`}>
				<div className="mb-4 relative">
					<PhoneNumberInput
						formik={formik}
						buyer={buyer}
						fieldName="phoneNumber"
						isDisabled={!!buyer}
						invalidCallback={() => {
							formik.setFieldValue("writtenAgreementExecuted", false);
						}}
					/>
				</div>

				<div className="mb-4">
					<label className="block text-gray-700">
						<input disabled={!!buyer || !isValidPhoneNumber(formik.values.phoneNumber)} type="checkbox" name="writtenAgreementExecuted" checked={formik.values.writtenAgreementExecuted} onChange={formik.handleChange} onBlur={formik.handleBlur} className="mr-2 checkbox-registered-required" />
						<span className={`text-secondary ${!!buyer || !isValidPhoneNumber(formik.values.phoneNumber) ? "disabled-text" : ""}`}>I verify that I have a written agreement with this buyer.</span>
						{asterisk}
						<span data-tip="<div style='width:320px'>A buyer registration agreement is a contract between a buyer and a real estate agent that outlines the terms and conditions under which the agent will represent the buyer in purchasing a property.</div>" data-for="tooltip">
							<ExclamationCircleIcon style={{ display: "inline-block" }} width={20} />
						</span>
						<ReactTooltip id="tooltip" place="top" html={true} />
					</label>
					<FormikError formik={formik} field="writtenAgreementExecuted" />
				</div>
				{formik.values.phoneNumber && formik.values.phoneNumber.length === 12 && formik.values.writtenAgreementExecuted && (
					<>
						<div className="mb-4">
							<FormInput fieldName="name" label="Name" type="text" formik={formik} />
						</div>

						<div className="mb-4">
							<FormInput fieldName="emailAddress" label="Email" type="email" formik={formik} />
						</div>

						<div className="mb-4">
							<label className="block ">Agreement1 Type{asterisk}</label>
							<select disabled={!!buyer} name="agreementType" value={formik.values.agreementType} onChange={formik.handleChange} onBlur={formik.handleBlur} className={` w-full px-3 py-2 border rounded opacity-100 input-registered-required ${!!buyer ? disabledStyle : "theme-bg"}`}>
								<option value="">Select Agreement Type</option>
								{agreementTypeOptions?.map((option: any, index: number) => (
									<option key={index} value={option.name}>
										{option.displayName}
									</option>
								))}
							</select>
							<FormikError formik={formik} field="agreementType" />
						</div>
						<div className="mb-4 flex space-x-4">
							<div className="w-1/2">
								<DateInput name="startDate" formik={formik} label={`Start Date`} max={formik.values.endDate ? new Date(new Date(formik.values.endDate).setDate(new Date(formik.values.endDate).getDate() - 1)).toISOString().split("T")[0] : undefined} disabled={!!buyer} />
							</div>
							<div className="w-1/2">
								<DateInput name="endDate" formik={formik} label={`End Date`} min={formik.values.startDate ? new Date(new Date(formik.values.startDate).setDate(new Date(formik.values.startDate).getDate() + 1)).toISOString().split("T")[0] : undefined} />
							</div>
						</div>
						{formik.values.cobuyers.length > 0 && (
							<>
								<h2 className="text-lg font-semibold mb-4">Co-buyer(s)</h2>
								<FieldArray
									name="cobuyers"
									render={(arrayHelpers) => (
										<div>
											{formik.values.cobuyers.map((cobuyer, index) => (
												<CobuyerForm key={index} index={index} arrayHelpers={arrayHelpers} formik={formik} cobuyer={cobuyer} buyer={buyer} theme={theme} />
											))}
										</div>
									)}
								/>
							</>
						)}
						{formik.values.cobuyers.length < 5 && (
							<FieldArray
								name="cobuyers"
								render={(arrayHelpers) => (
									<div>
										<button
											ref={addButtonRef}
											type="button"
											onClick={() => {
												arrayHelpers.push({ name: "", phoneNumber: "", emailAddress: "" });
												setTimeout(() => {
													addButtonRef.current?.scrollIntoView({ behavior: "smooth" });
												}, 1);
											}}
											className="add-cobuyer-btn flex items-center px-2 py-1 mt-2 mb-4 text-blue-500 bg-white border border-blue-500 rounded hover:bg-blue-100 text-sm"
										>
											<PlusCircleIcon className="h-4 w-4 mr-1" />
											{formik.values.cobuyers.length > 0 ? "Add Another Co-buyer" : "Add Co-buyer"}
										</button>
									</div>
								)}
							/>
						)}
						<button type="submit" className={`w-full py-2 rounded ${formik.isValid ? "bg-green-500 text-white" : "bg-gray-300 text-gray-500 cursor-not-allowed"}`} disabled={!formik.isValid || isSaving}>
							{isSaving ? <BeatLoader size={10} color="white" /> : buyer ? "Update Buyer Information" : "Register New Buyer"}
						</button>
					</>
				)}
			</form>
		</FormikProvider>
	);
};

export default RegisterBuyerForm;
