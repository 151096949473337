import React from "react";
import { FormikProps, getIn } from "formik";
import FormikError from "./FormikError"; // Adjust the import path as necessary

interface FormInputProps {
	fieldName: string;
	label: string;
	type: string;
	formik: FormikProps<any>;
	required?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({ fieldName, label, type, formik, required = true }) => {
	return (
		<div className="mb-4">
			<label className="block">
				{label} {required && <span className="text-red-500">*</span>}
			</label>
			<input type={type} name={fieldName} value={getIn(formik.values, fieldName)} onChange={formik.handleChange} onBlur={formik.handleBlur} className="w-full px-3 py-2 border rounded input-registered-required" />
			<FormikError formik={formik} field={fieldName} />
		</div>
	);
};

export default FormInput;
