import ReactSlidingPane from "react-sliding-pane";
import ContentContainer from "../../components/content/ContentContainer";
import "react-sliding-pane/dist/react-sliding-pane.css";
import InputMask from "react-input-mask";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import compStyles from "./buyerregistration.module.css";
import { ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, DetailRow, GridComponent, row } from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort } from "@syncfusion/ej2-react-grids";
import dayjs from "dayjs";
import { getValue } from "@syncfusion/ej2-base";
import { BeatLoader } from "react-spinners";
import { debounce, isArray } from "lodash";
import { PlusCircleIcon, ChatIcon, XCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { TableLoader } from "../../components/widgets/SkeletonScreens";
import { getAgreementTypes, getAllTickets, getBuyer, searchBuyerByPhoneNumber, ticketTypeahead } from "../../adapters";
import RegisterBuyerForm from "./RegisterBuyerForm";
import ActionsDropdown from "./components/ActionsDropdown";
import Modal from "react-modal";
import ReactModal from "react-modal";
import CancelRegistrationModal from "./components/CancelRegistrationModal";
import { format } from "path";
import { dateFormatter, displayToast, formatPhoneNumber, isValidPhoneNumber, shortDate } from "./utils";
import { ThemeContext } from "../../components/settings/theme/ThemeContext";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { user, userId } from "../user/selectors";
import { useAppDispatch } from "../../app/hooks";

export interface CoBuyer {
	name: Buyer["name"];
	phoneNumber: Buyer["phoneNumber"];
	emailAddress: Buyer["emailAddress"];
	otherAgentsExclusive?: Buyer["otherAgentsExclusive"];
	otherAgentsNonExclusive?: Buyer["otherAgentsNonExclusive"];
}

export interface Buyer {
	name: string;
	agreementId: string;
	phoneNumber: string;
	emailAddress: string;
	writtenAgreementExecuted: boolean;
	startDate: string;
	endDate: string;
	agreementType: string;
	otherAgentsExclusive?: number;
	otherAgentsNonExclusive?: number;
	registeredDate?: string;
	coBuyers?: CoBuyer[];
	status: string;
}
export interface CreateBuyer extends Buyer {
	memberId: string;
}

const BuyerRegistrationHome = (props: any) => {
	const styles = { ...compStyles };
	const gridControl = useRef<any>("");
	const gridControlCobuyer = useRef<any>("");
	const phoneSearchRef = useRef<HTMLInputElement>(null);
	const [firstLoad, setFirstLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showRegisterPopup, setShowRegisterPopup] = useState(false);
	const [showSidePanel, setShowSidePanel] = useState(false);

	const [activePage, setActivePage] = useState(0);
	const [agreementTypes, setAgreementTypes] = useState<string[]>([]);
	const [data, setData] = useState<{ result: any; count: string }>();
	const [sortParams, setSortParams] = useState<any[]>([]);
	const [selectedBuyer, setSelectedBuyer] = useState<any>();
	const [buyerToCancel, setBuyerToCancel] = useState<Buyer | null>(null);
	const [searchBuyerData, setSearchBuyerData] = useState<{ exclusiveAgreements: number; nonExclusiveAgreements: number }>({ exclusiveAgreements: 0, nonExclusiveAgreements: 0 });
	const dispatch = useAppDispatch();

	const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
	const { theme } = useContext(ThemeContext);
	let memberId = useSelector(userId);
	let user1 = useSelector(user);
	useEffect(() => {
		getTypes();
		fetchData();
	}, [activePage, sortParams]);

	useEffect(() => {
		if (gridControlCobuyer.current) {
			gridControlCobuyer.current.hideSpinner();
		}
		if (gridControl.current) {
			gridControl.current.hideSpinner();
		}
	}, [gridControlCobuyer, gridControl, data]);
	const fetchData = () => {
		console.log("user", user1);
		if (gridControl.current) {
			//gridControl.current.showSpinner();
		}

		getBuyer(memberId).then((response: any) => {
			if (gridControl.current) {
				gridControl.current.hideSpinner();
			}

			console.log("buyer response", response);
			setData({
				result: response.data || [],
				count: response?.data?.length,
			});
			if (gridControl && gridControl.current) {
				console.log("refreshing the table");
				gridControl.current.refresh();
			}
			setFirstLoad(false);
		});
	};

	const getTypes = () => {
		getAgreementTypes().then((response: any) => {
			setAgreementTypes(response.data);
		});
	};

	const clearPhoneSearch = () => {
		if (phoneSearchRef.current) {
			phoneSearchRef.current.value = "";
		}
	};

	const handleCancel = (buyer: Buyer) => {
		setBuyerToCancel(buyer);
		setIsModalOpen(true);
		console.log("Cancel action triggered");
	};

	const handleEdit = (e: Buyer) => {
		setSelectedBuyer(e);
		setShowSidePanel(true);
	};

	const handleRegisterNewBuyer = () => {
		setSelectedBuyer(undefined);
		setSearchPhoneNumber("");
		setShowSidePanel(true);
	};

	const handleSearch = (searchTerm: string) => {
		debounceSearch.cancel();
		searchBuyerByPhoneNumber(searchTerm, memberId)
			.then((response: any) => {
				setShowRegisterPopup(true);

				setSearchBuyerData(response.data);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				displayToast(dispatch, "error", "Buyer Registration", "An error has occured.");
			});
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, []);

	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		return <div className="cursor-pointer">{tag}</div>;
	};

	const headerTemplate = (props: any) => {
		return <div title={props.headerText}>{props.headerText}</div>;
	};

	const detailTemplate = (props: Buyer) => {
		return (
			<div className="detail-panel">
				<GridComponent
					ref={gridControlCobuyer}
					dataBound={() => {
						gridControl.current.hideSpinner();
						gridControlCobuyer.current.hideSpinner();
						setTimeout(() => {
							gridControl.current.hideSpinner();
							gridControlCobuyer.current.hideSpinner();
						}, 1000);
					}}
					dataSource={props.coBuyers}
				>
					<ColumnsDirective>
						<ColumnDirective field="name" headerText="Co-Buyer Name" width="140" />
						<ColumnDirective field="phoneNumber" headerText="Phone Number" width="150" />
						<ColumnDirective field="emailAddress" headerText="Email Address" width="200" />
						<ColumnDirective field="otherAgentsExclusive" headerText="Other Agents Exclusive" width="150" />
						<ColumnDirective field="otherAgentsNonExclusive" headerText="Other Agents Non-Exclusive" width="150" />
					</ColumnsDirective>
				</GridComponent>
			</div>
		);
	};

	const refreshTable = () => {
		console.log("REFRESHING TABLE");
		fetchData();
	};
	const onRowDataBound = (args: any) => {
		console.log("Data bound args: ", args);
		if (args.data && (!args.data.coBuyers || args.data.coBuyers.length === 0)) {
			console.log("this thing", args.row.classList);
			args.row.classList.add("e-hide-detailrow");
		}
	};
	return firstLoad ? (
		<TableLoader />
	) : (
		<span className="buyer-registration">
			{buyerToCancel && <CancelRegistrationModal fetchData={fetchData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} buyerToCancel={buyerToCancel} />}
			<ContentContainer title={"Buyer Registration"} actions={null}>
				<div className="w-full flex flex-col sm:flex-row justify-between gap-4 items-center">
					<div className="flex-1 sm:flex-none w-full sm:w-auto">
						<button onClick={handleRegisterNewBuyer} type="button" className="action-button bg-green-600 w-full sm:w-auto">
							<PlusCircleIcon className="h-4 w-4 mr-1" /> Register New Buyer
						</button>
					</div>
					<div className="flex-1 sm:flex-none w-full sm:w-1/2 lg:w-1/3 flex items-center">
						<div className="relative flex-1">
							{isLoading && (
								<span style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
									<BeatLoader size={5} color={theme === "light" ? "black" : "white"} />
								</span>
							)}
							<InputMask
								ref={phoneSearchRef}
								id="phone-search"
								mask="999-999-9999"
								type="text"
								className="w-full placeholder-secondary focus:ring-none focus:border-divider border-default rounded-md bg-secondary text-secondary"
								placeholder="Enter a Phone Number to Search Buyer Registry"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									if (isValidPhoneNumber(e.target.value)) {
										setIsLoading(true);
										setSearchPhoneNumber(e.target.value);
										debouncedHandleSearch(e.target.value);
									} else {
										setShowRegisterPopup(false);
									}
								}}
								onBlur={() => {
									setShowRegisterPopup(false);
								}}
								onFocus={() => {
									if (isValidPhoneNumber(searchPhoneNumber)) {
										setShowRegisterPopup(true);
									}
								}}
							/>
							{/* <input
								type="text"
								className="w-full placeholder-secondary focus:ring-none focus:border-divider border-default rounded-md bg-secondary text-secondary"
								placeholder={"Enter a Phone Number to Search Buyer Registry"}
								onChange={(e) => {
									const formatted = formatPhoneNumber(e.target.value);
									setSearchPhoneNumber(formatted);
									if (isValidPhoneNumber(formatted)) {
										setIsLoading(true);
										debouncedHandleSearch(formatted);
									} else {
										setShowRegisterPopup(false);
									}
								}}
								onBlur={() => {
									setShowRegisterPopup(false);
								}}
								onFocus={() => {
									if (isValidPhoneNumber(searchPhoneNumber)) {
										setShowRegisterPopup(true);
									}
								}}
								value={searchPhoneNumber}
							></input> */}
							{showRegisterPopup && (
								<div className={`${styles.search_results} shadow-lg ${theme === "light" ? "bg-white" : "theme-bg"}`}>
									<div>
										<span style={searchBuyerData.exclusiveAgreements > 0 ? { color: "red" } : undefined}>{searchBuyerData.exclusiveAgreements} Exclusive Agreements</span>
									</div>
									<div>
										<span>{searchBuyerData.nonExclusiveAgreements} Non-Exclusive Agreements</span>
									</div>
								</div>
							)}
						</div>
						<div className="ml-2">
							<button
								onClick={() => {
									clearPhoneSearch();
									setSelectedBuyer(undefined);
									setShowSidePanel(true);
								}}
								type="button"
								className="action-button bg-green-600 w-full sm:w-auto"
								style={{
									height: "39px",
									backgroundColor: !/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber) ? "gray" : "#059669",
									cursor: !/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber) ? "not-allowed" : "pointer",
								}}
								disabled={!/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber)}
							>
								<PlusCircleIcon className="h-4 w-4" />
								{!/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber)}
							</button>
						</div>
					</div>
				</div>
				<div className="syncfusion-grid w-full mt-5 buyer-registration-data-grid">
					<GridComponent allowPaging={true} pageSettings={{ pageCount: 4 }} rowDataBound={onRowDataBound} ref={gridControl} dataSource={data?.result} allowSorting={true} resizeSettings={{ mode: "Auto" }} detailTemplate={detailTemplate}>
						<ColumnsDirective>
							<ColumnDirective field="name" headerText="Buyer" template={nameTemplate} isPrimaryKey={true} width="150" headerTemplate={headerTemplate} />
							<ColumnDirective
								field="otherAgentsExclusive"
								headerText="Other Agents Exclusive"
								width="90"
								headerTemplate={(props: any) => (
									<span style={{ textTransform: "none" }}>
										EXCLUSIVE
										<span data-tip="Exclusive agreements between buyer and other agents" data-for="exclusive-tooltip">
											<ExclamationCircleIcon style={{ display: "inline-block", textTransform: "none", position: "relative", left: "2px", top: "-1px" }} width={14} />
										</span>
										<ReactTooltip id="exclusive-tooltip" place="top" html={true} />
									</span>
								)}
								template={(props: any) => <div style={{ color: props.otherAgentsExclusive > 0 ? "red" : "inherit" }}>{props.otherAgentsExclusive}</div>}
							/>
							<ColumnDirective
								field="otherAgentsNonExclusive"
								headerText="Other Agents Non-Exclusive"
								width="90"
								headerTemplate={(props: any) => (
									<span style={{ textTransform: "none" }}>
										NON-EXCLUSIVE
										<span data-tip="Non-exclusive agreements between buyer and other agents" data-for="non-exclusive-tooltip">
											<ExclamationCircleIcon style={{ display: "inline-block", position: "relative", left: "2px", top: "-1px" }} width={14} />
										</span>
										<ReactTooltip id="non-exclusive-tooltip" place="top" html={true} />
									</span>
								)}
							/>
							{/* <ColumnDirective
								field="coBuyerName"
								headerText="Co-Buyer(s)"
								width="150"
								headerTemplate={headerTemplate}
								template={(props: any) => (
									<ul className="list-disc list-inside">
										{props.coBuyers && props.coBuyers.length > 0 ? (
											props.coBuyers.map((coBuyer: CoBuyer, index: number) => (
												<li key={index} className="text-sm">
													{coBuyer.name}
												</li>
											))
										) : (
											<li className="text-sm text-gray-500">None</li>
										)}
									</ul>
								)}
							/> */}
							<ColumnDirective field="phoneNumber" headerText="Phone Number" width="100" />
							<ColumnDirective
								field="registeredDate"
								headerText="Registered Date"
								template={(props: any) => {
									return <>{shortDate(props.registeredDate)}</>;
								}}
								width="100"
								headerTemplate={headerTemplate}
							/>
							<ColumnDirective field="agreementType" headerText="Agreement Type" width="100" headerTemplate={headerTemplate} template={(props: { agreementType: string }) => <div>{props.agreementType.charAt(0).toUpperCase() + props.agreementType.slice(1)}</div>} />
							<ColumnDirective
								field="endDate"
								headerText="Agreement Expiration"
								valueAccessor={dateFormatter}
								width="100"
								headerTemplate={headerTemplate}
								template={(props: any) => {
									const daysUntilExpiration = dayjs(props.endDate).diff(dayjs(), "day");
									const isAboutToExpire = daysUntilExpiration <= 10 && daysUntilExpiration >= 0;
									return isAboutToExpire ? (
										<>
											<div data-for={`${props.endDate}`} data-tip={`This agreement expires in ${daysUntilExpiration} ${daysUntilExpiration === 1 ? "day" : "days"}`} style={{ color: isAboutToExpire ? "orange" : "inherit" }}>
												{shortDate(props.endDate)}
											</div>
											<ReactTooltip id={`${shortDate(props.endDate)}`} place="top" html={true} />
										</>
									) : (
										<>
											<div style={{ color: isAboutToExpire ? "orange" : "inherit" }}> {shortDate(props.endDate)}</div>
										</>
									);
								}}
							/>

							<ColumnDirective
								headerText="Actions"
								width="53"
								template={(props: any) => (
									<div className="float-right">
										<ActionsDropdown
											rowData={props}
											actions={[
												{ text: "Edit", callback: handleEdit },
												{ text: "Cancel", callback: handleCancel },
											]}
										/>
									</div>
								)}
							/>
						</ColumnsDirective>
						<Inject services={[Sort, DetailRow, Page]} />
					</GridComponent>
				</div>
				<ReactSlidingPane
					isOpen={showSidePanel}
					width={window.innerWidth <= 768 ? "100%" : "35%"}
					title={selectedBuyer ? "Update Buyer Information" : "Register New Buyer"}
					onRequestClose={() => {
						setSearchPhoneNumber("");
						setShowSidePanel(false);
					}}
					closeIcon={
						window.innerWidth <= 768 ? (
							<div>
								<XCircleIcon style={{ width: "28px" }} />
							</div>
						) : null
					}
				>
					<RegisterBuyerForm
						agreementTypeOptions={agreementTypes}
						refreshTable={() => {
							refreshTable();
						}}
						setShowSidePanel={setShowSidePanel}
						buyer={selectedBuyer}
						searchPhoneNumber={searchPhoneNumber}
					/>
				</ReactSlidingPane>
			</ContentContainer>
		</span>
	);
};

export default BuyerRegistrationHome;
