import React from "react";
import { FieldArrayRenderProps, FormikProps } from "formik";
import { MinusCircleIcon } from "@heroicons/react/solid";
import FormInput from "./FormInput"; // Adjust the import path as necessary
import PhoneNumberInput from "../PhoneNumberInput";

interface CoBuyerSectionProps {
	index: number;
	arrayHelpers: FieldArrayRenderProps;
	formik: FormikProps<any>;
	cobuyer: any;
	buyer: any;
	theme: string;
}

const CobuyerForm: React.FC<CoBuyerSectionProps> = ({ index, arrayHelpers, formik, cobuyer, buyer, theme }) => {
	const isExistingCobuyer = !!buyer?.coBuyers?.some((existingCobuyer: any) => existingCobuyer.phoneNumber === cobuyer.phoneNumber && existingCobuyer.name === cobuyer.name && existingCobuyer.emailAddress === cobuyer.emailAddress);
	return (
		<div className={`mt-4 p-4 rounded mb-4 ${theme === "light" ? "cobuyer-container" : ""}`} style={theme === "dark" ? { background: "#253445" } : theme === "contrast" ? { background: "#292828" } : undefined}>
			<h4 className="text-lg font-semibold mb-4">
				Co-buyer {index + 1} Information{" "}
				<button type="button" onClick={() => arrayHelpers.remove(index)} className="cobuyer-remove-btn float-right flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded hover:bg-red-100 text-sm">
					<MinusCircleIcon className="h-4 w-4 mr-1" />
					Remove Co-buyer
				</button>
			</h4>
			<div key={index}>
				<div className="mb-4 relative">
					<PhoneNumberInput formik={formik} buyer={cobuyer} fieldName={`cobuyers[${index}].phoneNumber`} isDisabled={isExistingCobuyer} />
				</div>
				<div key={index} className="mb-4">
					<FormInput label="Name" fieldName={`cobuyers[${index}].name`} type="text" formik={formik} />
					<FormInput label="Email" fieldName={`cobuyers[${index}].emailAddress`} type="email" formik={formik} />
				</div>
			</div>
		</div>
	);
};

export default CobuyerForm;
