import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React from "react";

interface Action {
	text: string;
	callback: (rowData: any) => void;
}

interface ActionsDropdownProps {
	actions: Action[];
	rowData: any;
}

const ActionsDropdown: React.FC<ActionsDropdownProps> = ({ actions, rowData }) => {
	const items = actions.map((action) => ({ text: action.text }));

	return (
		<div>
			<DropDownButtonComponent
				id="element"
				iconCss="stacked-ellipsis-icon"
				items={items}
				select={(args) => {
					const action = actions.find((action) => action.text === args.item.text);
					if (action) {
						action.callback(rowData);
					}
				}}
			></DropDownButtonComponent>
		</div>
	);
};

export default ActionsDropdown;
